<template>
  <div class="Map">
    <baidu-map
      class="map"
      :center="center"
      :zoom="zoom"
      @ready="handler"
      @click="getClickInfo"
      :scroll-wheel-zoom="true"
    >
      <div v-for="(item, i) in siteVos" :key="i" >
        <!-- 在线 -->
       <template v-if="showSiteVos">
          <bm-marker
          v-if="
            item.deviceStatus == '0' &&
              item.waterLevelAlarmStatus == '0' &&
              item.electricityAlarmStatus == '0'
          "
          :position="{ lng: item.lng, lat: item.lat }"
          :icon="{url:require('@/assets/images/bz/zaixian.png'),size: { width: 35, height: 41 }}"
          :zIndex="9"
          @mouseover="openInfoBox(item.lng, item.lat, item.id)"
          @mouseout="closeInfoBox"
          @click="goPush(item.id)"
        >
          <!-- <bm-label
            :content="item.siteName + ' ' + item.currentLevel + 'cm'"
            :zIndex="9"
            :labelStyle="{
              border: '1px solid #0E7771',
              color: '#000000',
              fontSize: '17px',
              textIndent: '20px',
              paddingRight: '10px',
              borderRadius: '8px'
            }"
            :offset="{ width: 20, height: 8 }"
            @mouseover="openInfoBox(item.lng, item.lat, item.id)"
            @mouseout="closeInfoBox"
          /> -->
        </bm-marker>

        <!-- 报警 -->
        <bm-marker
          v-if="
            item.deviceStatus == '0' &&
              (item.waterLevelAlarmStatus == '1' ||
                item.electricityAlarmStatus == '1')
          "
          :position="{ lng: item.lng, lat: item.lat }"
           :icon="{url:require('@/assets/images/bz/baojing.png'),size: { width: 35, height: 41 }}"
          :zIndex="9"
          @mouseover="openInfoBox(item.lng, item.lat, item.id)"
          @mouseout="closeInfoBox"
          @click="goPush(item.id)"
        >
          <!-- <bm-label
            :content="item.siteName + ' ' + item.currentLevel + 'cm'"
            :zIndex="9"
            :labelStyle="{
              border: '1px solid #f14c4c',
              color: '#000000',
              fontSize: '17px',
              textIndent: '20px',
              paddingRight: '10px',
              borderRadius: '8px'
            }"
            :offset="{ width: 20, height: 8 }"
            @mouseover="openInfoBox(item.lng, item.lat, item.id)"
            @mouseout="closeInfoBox"
          /> -->
        </bm-marker>
        <!-- 离线 -->
        <bm-marker
          v-if="item.deviceStatus == '1'"
          :position="{ lng: item.lng, lat: item.lat }"
          :icon="{url:require('@/assets/images/bz/lixian.png'),size: { width: 35, height: 41 }}"
          :zIndex="9"
          @mouseover="openInfoBox(item.lng, item.lat, item.id)"
          @mouseout="closeInfoBox"
          @click="goPush(item.id)"
        >
          <!-- <bm-label
            :content="item.siteName + ' ' + item.currentLevel + 'cm'"
            :zIndex="9"
            :labelStyle="{
              border: '1px solid #676767',
              color: '#000000',
              fontSize: '17px',
              textIndent: '20px',
              paddingRight: '10px',
              borderRadius: '8px'
            }"
            :offset="{ width: 20, height: 8 }"
            @mouseover="openInfoBox(item.lng, item.lat, item.id)"
            @mouseout="closeInfoBox"
          /> -->
        </bm-marker>
        </template>
      </div>

      <div class="Rain" v-for="(items, index) in rainVos" :key="'Rain-1' + index">

        <!-- <bm-marker
          v-if="items.rainRunStatus == '0'"
          :position="{ lng: items.lng, lat: items.lat }"
          :icon="{
            url:
              'https://qiniu1.xuruidea.com/27/20210105/rainGreenIcon1609816698958.png',
            size: { width: 38, height: 46 }
          }"
          :zIndex="9"
          @mouseover="
            openRainBox(items.lng, items.lat, items.id, items.rainName)
          "
          @mouseout="closeInfoBox"
        >
          <bm-label
            :content="items.rainName"
            :zIndex="9"
            :labelStyle="{
              border: '1px solid #0E7771',
              color: '#000000',
              fontSize: '17px',
              textIndent: '20px',
              paddingRight: '10px',
              borderRadius: '8px'
            }"
            :offset="{ width: 20, height: 8 }"
            @mouseover="
              openRainBox(items.lng, items.lat, items.id, items.rainName)
            "
            @mouseout="closeInfoBox"
          />
        </bm-marker> -->
        <!-- <bm-marker
          v-if="items.rainRunStatus == '1'"
          :position="{ lng: items.lng, lat: items.lat }"
          :icon="{
            url:
              'https://qiniu1.xuruidea.com/27/20210105/rainGrayIcon1609816696177.png',
            size: { width: 38, height: 46 }
          }"
          :zIndex="9"
          @mouseover="
            openRainBox(items.lng, items.lat, items.id, items.rainName)
          "
          @mouseout="closeInfoBox"
        >
          <bm-label
            :content="items.rainName"
            :zIndex="9"
            :labelStyle="{
              border: '1px solid #676767',
              color: '#000000',
              fontSize: '17px',
              textIndent: '20px',
              paddingRight: '10px',
              borderRadius: '8px'
            }"
            :offset="{ width: 20, height: 8 }"
            @mouseover="
              openRainBox(items.lng, items.lat, items.id, items.rainName)
            "
            @mouseout="closeInfoBox"/>
        </bm-marker> -->
        <!-- <bm-marker
          v-if="items.rainRunStatus == '2'"
          :position="{ lng: items.lng, lat: items.lat }"
          :icon="{
            url:
              'https://qiniu1.xuruidea.com/27/20210105/rainBlueIcon1609816693319.png',
            size: { width: 38, height: 46 }
          }"
          :zIndex="9"
          @mouseover="
            openRainBox(items.lng, items.lat, items.id, items.rainName)
          "
          @mouseout="closeInfoBox"
        >
          <bm-label
            :content="items.rainName"
            :zIndex="9"
            :labelStyle="{
              border: '1px solid #1c89fb',
              color: '#000000',
              fontSize: '17px',
              textIndent: '20px',
              paddingRight: '10px',
              borderRadius: '8px'
            }"
            :offset="{ width: 20, height: 8 }"
            @mouseover="
              openRainBox(items.lng, items.lat, items.id, items.rainName)
            "
            @mouseout="closeInfoBox"
          />
        </bm-marker> -->
      </div>
      <!-- 渠道流量计  -->

      <div v-for="(item,index) in ditchFlowMeters" :key="'ditchFlowMeters' + index">
          <template  v-if="showFlowMeters">

          <bm-marker :position="{ lng: item.lng, lat: item.lat }" :icon="{url:require('@/assets/images/bz/llj.png'),size: { width: 32, height: 37 }}"
                    :zIndex="9" @mouseover="openDitchFlowMeterBox(item.lng, item.lat, item.meterId)" @mouseout="closeDitchFlowMeterBox">
              <!-- <bm-label
                      :content="item.meterName"
                      :zIndex="9"
                      :labelStyle="{
                          border: '1px solid #0E7771',
                          color: '#000000',
                          fontSize: '17px',
                          textIndent: '20px',
                          paddingRight: '10px',
                          borderRadius: '8px'
                      }"
                      :offset="{ width: 20, height: 8 }"
                      @mouseover="openDitchFlowMeterBox(item.lng, item.lat, item.meterId)"
                      @mouseout="closeDitchFlowMeterBox"
              /> -->
          </bm-marker>
          </template>
      </div>

      <!-- 流量计  -->

      <div v-for="(item,index) in flowMeters" :key="'flowMeters' + index">
        <template  v-if="showFlowMeters">

            <bm-marker :position="{ lng: item.lng, lat: item.lat }" :icon="{url:require('@/assets/images/bz/llj.png'),size: { width: 32, height: 37 }}"
                       :zIndex="9" @mouseover="openFlowMeterBox(item.lng, item.lat, item.devSn)" @mouseout="closeFlowMeterBox">
                <!-- <bm-label
                        :content="item.name"
                        :zIndex="9"
                        :labelStyle="{
                          border: '1px solid #0E7771',
                          color: '#000000',
                          fontSize: '17px',
                          textIndent: '20px',
                          paddingRight: '10px',
                          borderRadius: '8px'
                      }"
                        :offset="{ width: 20, height: 8 }"
                        @mouseover="openFlowMeterBox(item.lng, item.lat, item.devSn)"
                        @mouseout="closeFlowMeterBox"
                /> -->
            </bm-marker>
            </template>
        </div>

      <!-- 泵站  -->

      <div v-for="(item,index) in pumpStations" :key="'pumpStations' + index" >
        <template v-if="showPumpStations">

            <bm-marker :position="{ lng: item.lng, lat: item.lat }" :icon="{url:require('@/assets/images/bz/bz.png'),size: { width: 31, height: 35 }}"
                       :zIndex="9" @mouseover="openPumpMeterBox(item.lng, item.lat, item.id)" @mouseout="closePumpBox" @click="toPumpStationPush(item.id,item.staId)">
                <!-- <bm-label
                        :content="item.staName"
                        :zIndex="9"
                        :labelStyle="{
                          border: '1px solid #0E7771',
                          color: '#000000',
                          fontSize: '17px',
                          textIndent: '20px',
                          paddingRight: '10px',
                          borderRadius: '8px'
                      }"
                        :offset="{ width: 20, height: 8 }"
                        @mouseover="openPumpMeterBox(item.lng, item.lat, item.id)"
                        @mouseout="closePumpBox"
                /> -->
            </bm-marker>
           </template>
        </div>

    </baidu-map>

    <!-- 雨情状态 -->
    <!-- <div class="rainStatus Card-b-c"> -->
<!--      <div class="title">-->
<!--        <i class="line"></i>-->
<!--        <div class="title-text font-s-20 font-w-b">雨情状态 :</div>-->
<!--      </div>-->
<!--      <ul class="flex flex-j-s-a ul-active">-->
<!--        <li class="flex">-->
<!--          <span class="circle-green"></span>-->
<!--          <div class="text"><p>正常</p></div>-->
<!--          <div class="numberText">-->
<!--            <p>{{ normalRainCount }}</p>-->
<!--          </div>-->
<!--        </li>-->
<!--        <li class="flex">-->
<!--          <span class="circle-blue"></span>-->
<!--          <div class="text"><p>下雨</p></div>-->
<!--          <div class="numberText">-->
<!--            <p>{{ warningRainCount }}</p>-->
<!--          </div>-->
<!--        </li>-->
<!--        <li class="flex">-->
<!--          <span class="circle-gray"></span>-->
<!--          <div class="text"><p>离线</p></div>-->
<!--          <div class="numberText">-->
<!--            <p>{{ offlineRainCount }}</p>-->
<!--          </div>-->
<!--        </li>-->
<!--      </ul>-->
      <!-- 闸门/橡胶坝状态 -->
      <!-- <div class="title">
        <i class="line"></i>
        <div class="title-text font-s-20 font-w-b">状态：</div>
      </div>
      <ul class="flex flex-j-s-a abul">
        <li class="flex">
          <span class="circle-green"></span>
          <div class="text"><p>正常</p></div>
          <div class="numberText">
            <p>{{ normalSiteCount }}</p>
          </div>
        </li>

        <li class="flex">
          <span class="circle-gray"></span>
          <div class="text"><p>离线</p></div>
          <div class="numberText">
            <p>{{ offlineSiteCount }}</p>
          </div>
        </li>
         <li class="flex">
          <span class="circle-red"></span>
          <div class="text"><p>报警</p></div>
          <div class="numberText">
            <p>{{ warningSiteCount }}</p>
          </div>
        </li>
      </ul> -->

        <!-- <div class="title">
            <i class="line"></i>
            <div class="title-text font-s-20 font-w-b">流量计/泵站状态 :</div>
        </div>
        <div class="indexLlj">
            <ul class="indexLlj_list">
                <li class="indexLlj_item"><a class="img"><img src="@/assets/images/bz/lljsmall.png" alt=""></a>流量计<a class="a-display">{{flowMeterCount}}</a></li>
                <li class="indexLlj_item"><a class="img"><img src="@/assets/images/bz/qdlljsmall.png" alt=""></a>渠道流量计<a class="a-display">{{ditchFlowMeterCount}}</a></li>
                <li class="indexLlj_item"><a class="img"><img src="@/assets/images/bz/bzOfflineSmall.png" alt=""></a>泵站离线：23</li>
                <li class="indexLlj_item"><a class="img"><img src="@/assets/images/bz/bzOnlineSmall.png" alt=""></a>泵站<a class="a-display">{{pumpStationCount}}</a></li>
            </ul>
        </div> -->
    <!-- </div> -->
    <!-- 状态 -->
    <div class="map-data-tip" ref="mapData">
      <div class="typeOne">
        <span >状态:</span>
      </div>
     <div class="typeTwo">
        <div class="tip-type">
          <span >正常</span>
           <span class="green"></span>
        </div>
        <div class="tip-type">
          <span >离线</span>
           <span class="gray"></span>
        </div>
        <div class="tip-type">
          <span >报警</span>
           <span class="red"></span>
        </div>
     </div>
    </div>
    <div class="map-checkbox">
       <el-radio-group v-model="checkboxGroup2"  @change="handleCheckedCitiesChange">
      <el-radio-button v-for="city in cities" :label="city" :key="city">{{city}}</el-radio-button>
    </el-radio-group>
    </div>
    <div class="icoQuan">
      <div><img src="@/assets/images/ico/icolj.png" alt=""></div>
      <div ><img src="@/assets/images/ico/icozai.png" alt=""></div>
      <div ><img style="padding-left: 15px;" src="@/assets/images/ico/icollj.png" alt=""></div>
    </div>
  </div>
</template>

<script>
const cityOptions = ['全选','泵站', '拦河闸', '测流点'];
import {
  getAllSiteList,
  getSiteByIdList,
  getRainById,
  getDitchFlowMeter,
  getFlowMeter,
  getPumpStation
} from '../../../../api/index'
import { llj, qdllj, bz } from '@/assets/js/html'

export default {
  props: {
    siteId: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      infoBox: null,
      lastInfoBox: null,
      checkboxGroup2: '全选',
      checkAll:true,
      isIndeterminate: true,
      cities: cityOptions,
      showPumpStations:true,
      showFlowMeters:true,
      showDitchFlowMeters:true,
      showSiteVos:true,
      isMains: '', // 市电或者太阳能
      siteItemVo: {}, // 获取到的站点信息
      gateVo: {}, // 闸门信息
      siteVos: [], // 站点列表
      rainVos: [], // 雨情列表
      ditchFlowMeters: [], // 渠道流量计
      ditchFlowMeterCount: '',
      flowMeters: [], // 流量计
      flowMeterCount: '',
      pumpStations: [], // 泵站
      pumpStationCount: '',
      center: {
        lng: 117.58139,
        lat: 37.64198
      },
      zoom: 12,
      // 顺序 雨情 站点 正常 掉线 报警
      normalRainCount: '',
      normalSiteCount: '',
      offlineRainCount: '',
      offlineSiteCount: '',
      warningRainCount: '',
      warningSiteCount: '',
      rainfallTime: '', // 降雨时长
      rainfall: '' // 降雨量
    }
  },
  watch: {
    '$store.state.wsRainData' (e) {
      this.normalRainCount = e.normalRainCount
      this.normalSiteCount = e.normalSiteCount
      this.offlineRainCount = e.offlineRainCount
      this.offlineSiteCount = e.offlineSiteCount
      this.warningRainCount = e.warningRainCount
      this.warningSiteCount = e.warningSiteCount
      // this.ditchFlowMeterCount = e.ditchFlowMeterCount
      // this.flowMeterCount = e.flowMeterCount
      // this.pumpStationCount = e.pumpStationCount
    },
    checkAll(e){
      // if(e){
      //       this.showPumpStations=true
      //         this.showFlowMeters=true
      //         this.showSiteVos=true
      //   }
    }
  },
  mounted () {
    this.show()
  },
  methods: {
    show(){
      getAllSiteList().then(res => {
      // console.log('getAllSiteList', res)
      // this.center = res.data.siteVos[0]
      this.siteVos = res.data.siteVos
      this.rainVos = res.data.rainVos
      this.ditchFlowMeters = res.data.ditchFlowMeters.map(item => { return { ...item, lng: item.lon } })
      this.ditchFlowMeterCount = res.data.ditchFlowMeterCount
      this.flowMeters = res.data.flowMeters.map(item => { return { ...item, lng: item.lon } })
      this.flowMeterCount = res.data.flowMeterCount
      this.pumpStations = res.data.pumpStations.map(item => { return { ...item, lng: item.lon } })
      this.pumpStationCount = res.data.pumpStationCount
      this.normalRainCount = res.data.normalRainCount
      this.normalSiteCount = res.data.normalSiteCount
      this.offlineRainCount = res.data.offlineRainCount
      this.offlineSiteCount = res.data.offlineSiteCount
      this.warningRainCount = res.data.warningRainCount
      this.warningSiteCount = res.data.warningSiteCount
    })
    },
     handleCheckAllChange(val) {
        // this.checkboxGroup2 = val ? cityOptions : [];
        // this.isIndeterminate = false;
        // if(val){
        //     this.showPumpStations=true
        //       this.showFlowMeters=true
        //       this.showSiteVos=true
        // }else{
        //      this.showPumpStations=false
        //       this.showFlowMeters=false
        //       this.showSiteVos=false
        // }

      },
      handleCheckedCitiesChange(value) {
        // console.log(value);
        if(value=='泵站'){
               this.showPumpStations=true
               this.showSiteVos=false
               this.showFlowMeters=false
          }
        if(value=='测流点'){
              this.showFlowMeters=true
              this.showPumpStations=false
              this.showSiteVos=false
          }
        if(value=='拦河闸'){
               this.showSiteVos=true
               this.showPumpStations=false
               this.showFlowMeters=false
          }
        if(value=='全选'){
               this.showSiteVos=true
               this.showPumpStations=true
               this.showFlowMeters=true
          }

      },
    goPush (id) {
      this.$router.push({ path: `/main/StationManage/${id}` })
    },
    toPumpStationPush (id, staId) {
      this.$router.push({ path: `/main/pumpStation/${id}/1-1` })
    },
    // 打开信息窗口
    openInfoBox (lng, lat, id) {
      // 鼠标移入获取接口数据
      getSiteByIdList({ id: id }).then(res => {
        this.isMains = res.data.isMains
        this.siteItemVo = res.data.siteItemVo
        this.gateVo = res.data.gateVo
// <div class="rainBox_title_img" οnclick="closeBtn" >
//             <img src="https://qiniu1.xuruidea.com/23001611824666_.pic_hd.jpg"  alt="" />
//           </div>
        const html = `<div class="rain">
    <div class="rainBox">
      <div style="margin:0 12px">
        <!-- 头部区域 -->
        <div class="rainBox_title flex flex-a-c ">
          <div class="rainBox_title_num ">
            <span style="font-size:12px">${this.siteItemVo.updateTime}</span>
          </div>
          <div
            class="rainBox_title_text  font-w-b"
            style="color:#333333;font-size:14px"
          >
           ${this.siteItemVo.siteName}

          </div>

        </div>
        <div class="polyin"></div>
        <!-- 闸门信息 -->
        <div class="gate">
          <div class="gate_title flex flex-a-c ">
            <p class="font-w-b" style="font-size:14px">闸门信息</p>
          </div>
          <div class="gate_text">
            <ul class="flex ul_one">
              <li>
                <div class=" font-w-b t-x" style="color:#11B36C;font-size:16px;">
                 ${this.gateVo.gateHeight}
                </div>
              </li>
              <li>
                <div class=" font-w-b t-x" style="color:#333333;font-size:16px">
               ${this.gateVo.gateHeightLimit}
                </div>
              </li>
            </ul>
            <ul class="flex ul_two">
              <li>
                <div class=" t-x font-s-12" style="color:#11B36C;font-weight: bold;">
                  闸门高度(cm)
                </div>
              </li>
              <li>
                <div class=" t-x font-s-12" style="color:#666">
                  闸门限高(cm)
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div class="polyin"></div>
        <!-- 水文信息 -->
        <div class="waterMes">
          <div class="waterMes_title flex flex-a-c ">
            <p class="font-w-b" style="font-size:14px">水文信息</p>
          </div>
          <div class="waterMes_text">
            <ul class="flex ul_one">
              <li>
                <div
                  class=" font-w-b t-x"
                  style="color:#11B36C;font-size:16px"
                >
                 ${this.siteItemVo.gateLevel}
                </div>
              </li>
              <li>
                <div
                  class=" font-w-b t-x"
                  style="color:#FB4D45;font-size:16px"
                >
                 ${this.siteItemVo.warningLevel}
                </div>
              </li>
              <li>
                <div
                  class=" font-w-b t-x"
                  style="color:#333333;font-size:16px"
                >
                 ${this.siteItemVo.traffic}
                </div>
              </li>
              <li>
                <div
                  class=" font-w-b t-x"
                  style="color:#333333;font-size:16px"
                >
                 ${this.siteItemVo.velocity}
                </div>
              </li>
            </ul>
            <ul class="flex ul_two">
              <li>
                <div class=" font-s-12 " style="color:#11B36C;margin-left:4px;font-weight: bold;">
                  当前水位(cm)
                </div>
              </li>
              <li>
                <div class=" font-s-12 t-x" style="color:#FB4D45;font-weight: bold;">
                  警戒水位(cm)
                </div>
              </li>
              <li>
                <div class=" font-s-12 t-x" style="color:#666;">
                  瞬时流量(m³/h)
                </div>
              </li>
              <li>
                <div class=" font-s-12 t-x" style="color:#666;">
                  流速(m/s)
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="polyin"></div>
        <!-- 气象信息 -->
        <div class="mete">
          <div class="mete_title flex flex-a-c ">
            <p class="font-w-b" style="font-size:14px">气象信息</p>
          </div>
          <div class="mete_text">
            <ul class="flex ul_two">
              <li class="flex">
                <div class="font-s-12" style="color:#666;margin-left:4px">
                  风速：
                </div>
                <div class="font-w-b font-s-12" style="color:#333">
                 ${this.siteItemVo.direction}风 ${this.siteItemVo.speed}
                </div>
              </li>
              <li class="flex" style="margin-left:calc(33/1920*100vw)">
                <div class="t-x font-s-12" style="color:#666;margin-left:4px">
                  温度：
                </div>
                <div class="font-w-b t-x font-s-12" style="color:#333">
                  ${this.siteItemVo.atmosphericTemperature}(℃)
                </div>
              </li>
              <li class="flex" style="margin-left:calc(33/1920*100vw)">
                <div class=" t-x font-s-12" style="color:#666;margin-left:4px">
                  湿度：
                </div>
                <div class="font-w-b t-x font-s-12" style="color:#333">
                ${this.siteItemVo.atmosphericHumidity}(RH)
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="polyin"></div>
        <div class="solar">
          <div class="solar_title flex flex-a-c ">
            <p class="font-w-b" style="font-size:14px">太阳能信息</p>
          </div>
          <div class="solar_text">
            <ul class="flex ul_two">
              <li class="flex" style="width:130px">
                <div class="font-s-12" style="color:#666;margin-left:4px;">
                  光伏电压：
                </div>
                <div class="font-w-b t-x font-s-12" style="color:#333">
                 ${this.siteItemVo.pvVoltage}(V)
                </div>
              </li>
              <li class="flex" style="width:120px">
                <div class=" font-s-12" style="color:#666">
                  光伏电流：
                </div>
                <div class="font-w-b t-x font-s-12" style="color:#333">
                 ${this.siteItemVo.pvCurrent}(A)
                </div>
              </li>
              <li class="flex">
                <div class="font-s-12" style="color:#666">
                  负载电流：
                </div>
                <div class="font-w-b t-x font-s-12" style="color:#333">
                  ${this.siteItemVo.loadCurrent}(A)
                </div>
              </li>
            </ul>
            <ul class="flex ul_two">
              <li class="flex" style="width:130px">
                <div class="font-s-12" style="color:#666;margin-left:4px">
                  电池温度：
                </div>
                <div class="font-w-b t-x font-s-12" style="color:#333">
                  ${this.siteItemVo.batteryTemperature}(℃)
                </div>
              </li>
              <li class="flex" style="width:120px">
                <div class=" font-s-12" style="color:#666">
                  电池电压：
                </div>
                <div class="font-w-b t-x font-s-12" style="color:#333">
                  ${this.siteItemVo.batteryVoltage}(V)
                </div>
              </li>
              <li class="flex">
                <div class=" font-s-12" style="color:#666">
                  剩余电量：
                </div>
                <div class="font-w-b t-x font-s-12" style="color:#333">
                 ${this.siteItemVo.soc}(%)
                </div>
              </li>
            </ul>
            <ul class="flex ul_two">
              <li class="flex" style="width:50%">
                <div class=" font-s-12" style="color:#666;margin-left:4px">
                  日发电量：
                </div>
                <div class="font-w-b t-x font-s-12" style="color:#333">
                  ${this.siteItemVo.dailyOutput}(KWH)
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>`

// <div class="rainBox_title_img" οnclick="closeBtn">
//             <img src="https://qiniu1.xuruidea.com/23001611824666_.pic_hd.jpg"  alt="" />
//           </div>
        const html1 = ` <div class="rain">
    <div class="rainBox">
      <div style="margin:0 12px">
        <!-- 头部区域 -->
        <div class="rainBox_title flex flex-a-c ">
          <div class="rainBox_title_num ">
            <span style="font-size:12px">${this.siteItemVo.updateTime}</span>
          </div>
          <div
            class="rainBox_title_text  font-w-b"
            style="color:#333333;font-size:14px"
          >
           ${this.siteItemVo.siteName}

          </div>

        </div>
        <div class="polyin"></div>
        <!-- 闸门信息 -->
        <div class="gate">
          <div class="gate_title flex flex-a-c ">
            <p class="font-w-b" style="font-size:14px">闸门信息</p>
          </div>
          <div class="gate_text">
            <ul class="flex ul_one">
              <li>
                <div class=" font-w-b t-x" style="color:#11B36C;font-size:16px">
                 ${this.gateVo.gateHeight}
                </div>
              </li>
              <li>
                <div class=" font-w-b t-x" style="color:#333333;font-size:16px">
               ${this.gateVo.gateHeightLimit}
                </div>
              </li>
            </ul>
            <ul class="flex ul_two">
              <li>
                <div class=" t-x font-s-12" style="color:#11B36C;font-weight: bold;">
                  闸门高度(cm)
                </div>
              </li>
              <li>
                <div class=" t-x font-s-12" style="color:#666">
                  闸门限高(cm)
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div class="polyin"></div>
        <!-- 水文信息 -->
        <div class="waterMes">
          <div class="waterMes_title flex flex-a-c ">
            <p class="font-w-b" style="font-size:14px">水文信息</p>
          </div>
          <div class="waterMes_text">
            <ul class="flex ul_one">
              <li>
                <div
                  class=" font-w-b t-x"
                  style="color:#11B36C;font-size:16px"
                >
                 ${this.siteItemVo.gateLevel}
                </div>
              </li>
              <li>
                <div
                  class=" font-w-b t-x"
                  style="color:#FB4D45;font-size:16px"
                >
                 ${this.siteItemVo.warningLevel}
                </div>
              </li>
              <li>
                <div
                  class=" font-w-b t-x"
                  style="color:#333333;font-size:16px"
                >
                 ${this.siteItemVo.traffic}
                </div>
              </li>
              <li>
                <div
                  class=" font-w-b t-x"
                  style="color:#333333;font-size:16px"
                >
                 ${this.siteItemVo.velocity}
                </div>
              </li>
            </ul>
            <ul class="flex ul_two">
              <li>
                <div class=" font-s-12" style="color:#11B36C;margin-left:4px;font-weight: bold;">
                  当前水位(cm)
                </div>
              </li>
              <li>
                <div class=" font-s-12 t-x" style="color:#FB4D45;font-weight: bold;">
                  警戒水位(cm)
                </div>
              </li>
              <li>
                <div class=" font-s-12 t-x" style="color:#666;">
                  瞬时流量(m³/h)
                </div>
              </li>
              <li>
                <div class=" font-s-12 t-x" style="color:#666;">
                  流速(m/s)
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="polyin"></div>
        <!-- 气象信息 -->
        <div class="mete">
          <div class="mete_title flex flex-a-c ">
            <p class="font-w-b" style="font-size:14px">气象信息</p>
          </div>
          <div class="mete_text">
            <ul class="flex ul_two">
              <li class="flex">
                <div class="font-s-12" style="color:#666;margin-left:4px">
                  风速：
                </div>
                <div class="font-w-b font-s-12" style="color:#333">
                 ${this.siteItemVo.direction}风 ${this.siteItemVo.speed}
                </div>
              </li>
              <li class="flex" style="margin-left:calc(33/1920*100vw)">
                <div class="t-x font-s-12" style="color:#666;margin-left:4px">
                  温度：
                </div>
                <div class="font-w-b t-x font-s-12" style="color:#333">
                  ${this.siteItemVo.atmosphericTemperature}(℃)
                </div>
              </li>
              <li class="flex" style="margin-left:calc(33/1920*100vw)">
                <div class=" t-x font-s-12" style="color:#666;margin-left:4px">
                  湿度：
                </div>
                <div class="font-w-b t-x font-s-12" style="color:#333">
                ${this.siteItemVo.atmosphericHumidity}(RH)
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="polyin"></div>
        <div class="solar">
          <div class="solar_title flex flex-a-c ">
            <p class="font-w-b" style="font-size:14px">太阳能信息</p>
          </div>
          <div class="solar_text">
            <ul class="flex ul_two">
              <li class="flex" style="width:130px">
                <div class="font-s-12" style="color:#666;margin-left:4px;">
                  A相电流：
                </div>
                <div class="font-w-b t-x font-s-12" style="color:#333">
               ${this.siteItemVo.aCurrent}(A)
                </div>
              </li>
              <li class="flex" style="width:120px">
                <div class=" font-s-12" style="color:#666">
                 B相电流：
                </div>
                <div class="font-w-b t-x font-s-12" style="color:#333">
               ${this.siteItemVo.bCurrent}(A)
                </div>
              </li>
              <li class="flex">
                <div class="font-s-12" style="color:#666">
                 C相电流：
                </div>
                <div class="font-w-b t-x font-s-12" style="color:#333">
                ${this.siteItemVo.cCurrent}(A)
                </div>
              </li>
            </ul>
            <ul class="flex ul_two">
              <li class="flex" style="width:130px">
                <div class="font-s-12" style="color:#666;margin-left:4px">
                 D相电流：
                </div>
                <div class="font-w-b t-x font-s-12" style="color:#333">
                 ${this.siteItemVo.dCurrent}(A)
                </div>
              </li>
              <li class="flex" style="width:120px">
                <div class=" font-s-12" style="color:#666">
                  A相电压：
                </div>
                <div class="font-w-b t-x font-s-12" style="color:#333">
                  ${this.siteItemVo.aVoltage}(V)
                </div>
              </li>
              <li class="flex">
                <div class=" font-s-12" style="color:#666">
                 B相电压：
                </div>
                <div class="font-w-b t-x font-s-12" style="color:#333">
                 ${this.siteItemVo.bVoltage}(V)
                </div>
              </li>
            </ul>
            <ul class="flex ul_two">
              <li class="flex" style="width:50%">
                <div class=" font-s-12" style="color:#666;margin-left:4px">
                  C相电压：
                </div>
                <div class="font-w-b t-x font-s-12" style="color:#333">
                  ${this.siteItemVo.cVoltage}(V)
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>`

        const opts = {
          boxStyle: {
            width: '400px',
            height: '350px'
          },
          closeIconMargin: 'calc(50/1080*100vh) calc(20/1920*100vw) 0 0',
          closeIconUrl: require('../../../.././assets/images/index/guanbi.png'),
          enableAutoPan: true,
          align: INFOBOX_AT_TOP
        }

        if (this.isMains == 1) {
          this.infoBox = new BMapLib.InfoBox(this.map, html1, opts)
        } else {
          this.infoBox = new BMapLib.InfoBox(this.map, html, opts)
        }
        const point = new BMap.Point(lng, lat)
        const marker = new BMap.Marker(point)
        if (this.lastInfoBox) {
          // 判断上一个窗体是否存在，若存在则执行close
          this.lastInfoBox.close()
        }
        this.lastInfoBox = this.infoBox
        this.infoBox.open(marker)
        document.querySelector('.map-checkbox').style.opacity = 0 // 隐藏雨情控件
        document.querySelector('.map-data-tip').style.opacity = 0 // 隐藏雨情控件
        document.querySelector('.icoQuan').style.opacity = 0 // 隐藏雨情控件
        var guanbiBtn = window.document.getElementsByClassName('rainBox_title_img')
        var that = this
        guanbiBtn[0].addEventListener('click', showMsg, false) // 鼠标单击的时候调用showMes这个函数
        function showMsg () {
          // alert("事件监听");
          that.infoBox.close()
          document.querySelector('.map-checkbox').style.opacity = 1 // 显示雨情控件
          document.querySelector('.map-data-tip').style.opacity = 1 // 显示雨情控件
          document.querySelector('.icoQuan').style.opacity = 1 // 显示雨情控件
        }
      })
    },

    closeInfoBox () {
      if (this.infoBox !== null) {
        this.infoBox.close()
        document.querySelector('.map-checkbox').style.opacity = 1 // 显示雨情控件
        document.querySelector('.map-data-tip').style.opacity = 1 // 显示雨情控件
        document.querySelector('.icoQuan').style.opacity = 1 // 显示雨情控件
      }
    },

    // 渠道流量计弹框
    openDitchFlowMeterBox (lng, lat, id) {
      getDitchFlowMeter({ meterId: id }).then(res => {
        // 渠道流量计
        const qdlljHtml = qdllj(res.data)
        const opts = {
          boxStyle: {
            width: '420px',
            height: '360px'
          },
          closeIconMargin: 'calc(50/1080*100vh) calc(20/1920*100vw) 0 0',
          closeIconUrl: require('../../../.././assets/images/index/guanbi.png'),
          enableAutoPan: true,
          align: INFOBOX_AT_TOP
        }

        this.infoBox = new BMapLib.InfoBox(this.map, qdlljHtml, opts)
        const point = new BMap.Point(lng, lat)
        const marker = new BMap.Marker(point)
        if (this.lastInfoBox) {
          // 判断上一个窗体是否存在，若存在则执行close
          this.lastInfoBox.close()
        }
        this.lastInfoBox = this.infoBox
        this.infoBox.open(marker)
        document.querySelector('.map-checkbox').style.opacity = 0 // 隐藏雨情控件
        document.querySelector('.map-data-tip').style.opacity = 0 // 隐藏雨情控件
        document.querySelector('.icoQuan').style.opacity = 0 // 隐藏雨情控件
        var guanbiBtn = window.document.getElementsByClassName('title_img')
        var that = this
        guanbiBtn[0].addEventListener('click', showMsg, false) // 鼠标单击的时候调用showMes这个函数
        function showMsg () {
          // alert("事件监听");
          that.infoBox.close()
          document.querySelector('.map-checkbox').style.opacity =1 // 显示雨情控件
          document.querySelector('.map-data-tip').style.opacity = 1 // 显示雨情控件
           document.querySelector('.icoQuan').style.opacity = 1 // 显示雨情控件
        }
      })
    },

    closeDitchFlowMeterBox () {
      if (this.infoBox !== null) {
        this.infoBox.close()
        document.querySelector('.map-checkbox').style.opacity = 1 // 显示雨情控件
        document.querySelector('.map-data-tip').style.opacity = 1 // 显示雨情控件
        document.querySelector('.icoQuan').style.opacity = 1 // 显示雨情控件
      }
    },

    // 流量计弹框
    openFlowMeterBox (lng, lat, id) {
      getFlowMeter({ devSn: id }).then(res => {
        // console.log(res)
        // 渠道流量计
        const lljHtml = llj(res.data)
        const opts = {
          boxStyle: {
            width: '420px',
            height: '360px'
          },
          closeIconMargin: 'calc(50/1080*100vh) calc(20/1920*100vw) 0 0',
          closeIconUrl: require('../../../.././assets/images/index/guanbi.png'),
          enableAutoPan: true,
          align: INFOBOX_AT_TOP
        }

        this.infoBox = new BMapLib.InfoBox(this.map, lljHtml, opts)
        const point = new BMap.Point(lng, lat)
        const marker = new BMap.Marker(point)
        if (this.lastInfoBox) {
          // 判断上一个窗体是否存在，若存在则执行close
          this.lastInfoBox.close()
        }
        this.lastInfoBox = this.infoBox
        this.infoBox.open(marker)
        document.querySelector('.map-checkbox').style.opacity = 0 // 隐藏雨情控件
        document.querySelector('.map-data-tip').style.opacity = 0 // 隐藏雨情控件
        document.querySelector('.icoQuan').style.opacity = 0 // 隐藏雨情控件
        var guanbiBtn = window.document.getElementsByClassName('title_img')
        var that = this
        guanbiBtn[0].addEventListener('click', showMsg, false) // 鼠标单击的时候调用showMes这个函数
        function showMsg () {
          // alert("事件监听");
          that.infoBox.close()
          document.querySelector('.map-checkbox').style.opacity = 1 // 显示雨情控件
          document.querySelector('.map-data-tip').style.opacity = 1 // 显示雨情控件
          document.querySelector('.icoQuan').style.opacity = 1 // 显示雨情控件
        }
      })
    },

    closeFlowMeterBox () {
      if (this.infoBox !== null) {
        this.infoBox.close()
        document.querySelector('.map-checkbox').style.opacity = 1 // 显示雨情控件
        document.querySelector('.map-data-tip').style.opacity = 1 // 显示雨情控件
        document.querySelector('.icoQuan').style.opacity = 1 // 显示雨情控件
      }
    },

    // 泵站弹框
    openPumpMeterBox (lng, lat, id) {
      getPumpStation({ id: id }).then(res => {
        const bzHtml = bz(res.data)
        const opts = {
          boxStyle: {
            width: '420px',
            height: '310px'
          },
          closeIconMargin: 'calc(50/1080*100vh) calc(20/1920*100vw) 0 0',
          closeIconUrl: require('../../../.././assets/images/index/guanbi.png'),
          enableAutoPan: true,
          align: INFOBOX_AT_TOP
        }

        this.infoBox = new BMapLib.InfoBox(this.map, bzHtml, opts)
        const point = new BMap.Point(lng, lat)
        const marker = new BMap.Marker(point)
        if (this.lastInfoBox) {
          // 判断上一个窗体是否存在，若存在则执行close
          this.lastInfoBox.close()
        }
        this.lastInfoBox = this.infoBox
        this.infoBox.open(marker)
        document.querySelector('.map-checkbox').style.opacity = 0 // 隐藏雨情控件
        document.querySelector('.map-data-tip').style.opacity = 0 // 隐藏雨情控件
        document.querySelector('.icoQuan').style.opacity = 0 // 隐藏雨情控件
        var guanbiBtn = window.document.getElementsByClassName('title_img')
        var that = this
        guanbiBtn[0].addEventListener('click', showMsg, false) // 鼠标单击的时候调用showMes这个函数
        function showMsg () {
          // alert("事件监听");
          that.infoBox.close()
          document.querySelector('.map-checkbox').style.opacity =1 // 显示雨情控件
          document.querySelector('.map-data-tip').style.opacity = 1 // 显示雨情控件
          document.querySelector('.icoQuan').style.opacity = 1 // 显示雨情控件
        }
      })
    },

    closePumpBox () {
      if (this.infoBox !== null) {
        this.infoBox.close()
        document.querySelector('.map-checkbox').style.opacity = 1 // 显示雨情控件
        document.querySelector('.map-data-tip').style.opacity = 1 // 显示雨情控件
        document.querySelector('.icoQuan').style.opacity = 1 // 显示雨情控件
      }
    },

    // 雨情弹框
    openRainBox (lng, lat, id, rainName) {
      getRainById({ id, id }).then(res => {
        this.rainfallTime = res.data.rainfallTime
        this.rainfall = res.data.rainfall
        const RainHtml = `
      <div class="RainBox">
      <button>${rainName}</button>
      <div><span>降雨量：</span><span>${this.rainfall}mm</span></div>
      <div class="timer"><span>降雨时长：</span><span>${this.rainfallTime}</span></div>
      </div>
      `
        const opts = {
          boxStyle: {
            width: 'calc(242/1920*100vw)',
            height: 'calc(120/1080*100vh)'
          },
          closeIconMargin: 'calc(15/1080*100vh) calc(10/1920*100vw) 0 0',
          closeIconUrl: require('../../../.././assets/images/index/rain.png'),
          enableAutoPan: true,
          align: INFOBOX_AT_TOP
        }
        this.infoBox = new BMapLib.InfoBox(this.map, RainHtml, opts)
        const point = new BMap.Point(lng, lat)
        const marker = new BMap.Marker(point)
        if (this.lastInfoBox) {
          // 判断上一个窗体是否存在，若存在则执行close
          this.lastInfoBox.close()
        }
        this.lastInfoBox = this.infoBox
        this.infoBox.open(marker)
      })
    },
    getBoundary (map, district) {
      var bdary = new BMap.Boundary()
      bdary.get(district, function (rs) {
        // 获取行政区域
        // map.clearOverlays(); //清除地图覆盖物
        var count = rs.boundaries.length // 行政区域的点有多少个
        if (count === 0) {
          /* alert('未能获取当前输入行政区域'); */
          return
        }
        const EN_JW = '180, 90;'
        const NW_JW = '-180,  90;'
        const WS_JW = '-180, -90;'
        const SE_JW = '180, -90;'
        // 东南西北四个角添加一个覆盖物
        const ply1 = new BMap.Polygon(rs.boundaries[0] + SE_JW + SE_JW + WS_JW + NW_JW + EN_JW + SE_JW,
          { strokeColor: 'none', fillColor: '#fff', fillOpacity: 1, strokeOpacity: 1 })
        map.addOverlay(ply1)
        var pointArray = []
        for (var i = 0; i < count; i++) {
          var ply = new BMap.Polygon(rs.boundaries[i], {
            strokeWeight: 2, // 设置多边形边线线粗
            strokeOpacity: 1, // 设置多边形边线透明度0-1
            StrokeStyle: 'dashed', // 设置多边形边线样式为实线或虚线，取值 solid 或 dashed
            strokeColor: '#009944', // 设置多边形边线颜色
            fillColor: '#8BFFCC', // 设置多边形填充颜色
            fillOpacity: 0.13 // 设置多边形填充颜色透明度0-1  注：标红的地放你们可以去掉看一下效果，自己体验一下
          }) // 建立多边形覆盖物
          map.addOverlay(ply) // 添加覆盖物
          // pointArray = pointArray.concat(ply.getPath())
        }
        // map.setViewport(pointArray) // 调整视野
      })
    },

    handler ({ BMap, map }) {
      this.map = map
      this.getBoundary(this.map, '阳信县')
    },

    infoWindowClose (marker) {
      marker.showFlag = false
    },
    infoWindowOpen (marker) {
      marker.showFlag = true
    },
    getClickInfo (e) {}
  }
}
</script>

<style lang="scss" scoped>
.map-checkbox{
  border: 3px solid #0196FF;
  display: flex;
  border-radius: 30px;
  padding: 3px;
  position: absolute;
  top: 5%;
  left: 2%;
  z-index: 4;
  opacity: 1;
}
.icoQuan{
  position: absolute;
  top: 6.1%;
  left: 9.4%;
  display: flex;
  width: 240px;
  opacity: 1;
  div{
    flex: 1;
    img{
      position: relative;
      z-index: 5;
    }
  }
}
::v-deep.el-radio-button:first-child .el-radio-button__inner{
  border-radius: 20px;
   padding: 8px 15px 8px 15px
}
::v-deep .el-radio-button__inner{
    border-radius: 20px;
    padding: 8px 15px 8px 35px;
    border:none;
  }
::v-deep.el-radio-button:first-child .el-radio-button__inner{
   border-radius: 20px;

}
 ::v-deep .el-radio-button:last-child .el-radio-button__inner{
     border-radius: 20px;
  }

// 状态
.map-data-tip {
  border: 1px solid #0196FF;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.35);
  border-radius: 10px;
  width: 130px;
  max-height: 300px;
  height: 100px;
  padding: 10px;
  position: absolute;
  top: 5%;
  right: 0;
  background: rgba(255, 255, 255,0.7);
  transform: translateX(-50%);
  display: flex;
  color: #434343;
   z-index:5;
   opacity: 1;
  //  flex-direction: column;
  // justify-content: space-around;

}
.typeOne{
  margin-top: 8px;
  padding-right: 15px;
  span{
    font-size: 15px;
    font-weight: bold;
  }
}
.tip-type {
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    //margin: 5px ;
    padding-right: 10px;
  }
  .gray{
    width: calc(20 / 1920 * 100vw);
    height: calc(5 / 1920 * 100vw);
    background: #676767;
  }
  .red{
   width: calc(20 / 1920 * 100vw);
    height: calc(5 / 1920 * 100vw);
    background: #F14D4D;
  }
  .green{
  width: calc(20 / 1920 * 100vw);
    height: calc(5 / 1920 * 100vw);
    background: #3D7EF2;
  }
}
.Map {
  width: calc(1031 / 1920 * 100vw);
  height: calc(986 / 1080 * 100vh);
  position: relative;
  // 雨情状态
  .rainStatus {
    position: absolute;
    top: calc(20 / 1920 * 100vw);
    left: calc(20 / 1920 * 100vw);
    width: calc(346 / 1920 * 100vw);
    /*height: calc(300 / 1080 * 100vh);*/
    opacity: 0.8;
    border: calc(2 / 1920 * 100vw) solid #0196ff;
    .title {
      margin-top: calc(5 / 1080 * 100vh);
      position: relative;
      height: calc(50 / 1080 * 100vh);
      .line::after {
        content: "";
        width: calc(3 / 1920 * 100vw);
        height: calc(17 / 1080 * 100vh);
        background-color: #3669ab;
        position: absolute;
        left: calc(17 / 1920 * 100vw);
        top: calc(13 / 1080 * 100vh);
      }
      .title-text {
        height: 100%;
        text-indent: calc(34 / 1920 * 100vw);
        line-height: calc(44 / 1080 * 100vh);
        color: #434343;
      }
    }
      .indexLlj{
          padding: 5px 12px;
          margin-bottom: 12px;
          .indexLlj_list{
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              .indexLlj_item{
                  display: flex;
                  width: 50%;
                  align-items: center;
                  color: #333333;
                  font-weight: 500;
                  .img{
                      display: block;
                      width: 20px;
                      height: 24px;
                      margin-right: 4px;
                      img{
                          width: 100%;
                          height: 100%;
                      }
                  }
                  .a-display{
                      display: block;
                      margin-left: 5px;
                      color: #333;
                      font-weight: bold;
                      font-size: calc(20 / 1920 * 100vw);
                  }
                  &:nth-child(3){
                      margin-top: 10px;
                  }
                  &:nth-child(4){
                      margin-top: 10px;
                  }
              }
          }
      }
  }
  ul {
    li {
      position: relative;
      color: #434343;
      .text {
        width: calc(48 / 1920 * 100vw);
        font-size: calc(14 / 1920 * 100vw);
        position: relative;
        left: calc(2 / 1920 * 100vw);
        p {
          position: absolute;

          left: calc(10 / 1920 * 100vw);
          bottom: 0;
        }
      }
      .numberText {
        width: calc(34 / 1920 * 100vw);
        position: relative;
        bottom: calc(1 / 1080 * 100vh);
        // p {
        //   color: #333;
        //   font-weight: bold;
        //   font-size: calc(30 / 1920 * 100vw);
        //   position: relative;
        //   font-size: calc(20 / 1920 * 100vw);
        //   // bottom: 0;
        //   top: 5px;
        // }
      }
    }
  }
  .abul{
    position: absolute;
    top: 35px;
    right: 13%;
  }
  .ul-active {
    padding-bottom: 10px;
  }
  .map {
    width: 100%;
    height: 100%;
  }
}

::v-deep .RainBox {
  width: calc(250 / 1920 * 100vw);
  height: calc(116 / 1080 * 100vh);
  background: rgba(243, 248, 255, 0.5);
  border: calc(3 / 1920 * 100vw) solid #1f78ec;
  border-radius: 8px;
  button {
    margin: calc(10 / 1080 * 100vh) 0;
    margin-left: calc(12 / 1920 * 100vw);
    width: calc(224 / 1920 * 100vw);
    height: calc(30 / 1080 * 100vh);
    background: #1f78ec;
    border-radius: 8px;
    color: #dbebf9;
    font-size: calc(18 / 1920 * 100vw);
    font-weight: bold;
    line-height: calc(20 / 1080 * 100vh) !important;
  }
  .timer {
    margin-top: calc(3 / 1080 * 100vh);
  }
  span:nth-child(1) {
    margin-left: calc(12 / 1080 * 100vh);
    font-size: calc(16 / 1920 * 100vw);
    color: #666666;
  }
  span:nth-child(2) {
    font-size: calc(16 / 1920 * 100vw);
    font-weight: bold;
    color: #333333;
  }
}

::v-deep .rain {
  padding: 3px;
  width: 386px;
  height: 342px;
  background: #217acc;
  box-shadow: 0px 0px 13px 0px rgba(6, 0, 1, 0.27);
  border-radius: 16px;
  .rainBox {
    // margin: 5px;
    width: 386px;
    height: 342px;
    background: #f3f6f8;
    border-radius: 16px;
    .rainBox_title {
      height: 30px;
      .rainBox_title_num {
        width: 150px;
        color: #333;
        font-weight: 400;
      }
      .rainBox_title_text {
        width: 200px;
        color: #217acc;
      }
      .rainBox_title_img {
        // margin-left: 155px;
        width: 15px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .polyin {
      height: 1px;
      background: #666666;
      opacity: 0.1;
      border-radius: 16px;
    }
    // 闸门信息
    .gate {
      height: 65px;
      .gate_title {
        margin-top: 8px;
        p {
          color: #333;
          margin-left: 4px;
        }
      }
      .gate_text {
        .ul_one {
          li {
            width: 50%;
          }
        }
        .ul_two {
          li {
            width: 50%;
          }
        }
      }
    }
    // 水文信息
    .waterMes {
      height: 65px;
      .waterMes_title {
        margin-top: 8px;
        p {
          color: #333;
          margin-left: 4px;
        }
      }
      .waterMes_text {
        margin-top: 5px;
      }
      .ul_one {
        li {
          width: 25%;
        }
      }
      .ul_two {
        li {
          width: 25%;
        }
      }
    }
    // 气象信息
    .mete {
      height: 45px;
      .mete_title {
        margin-top: 8px;
        p {
          color: #333;
          margin-left: 4px;

        }
      }
      .mete_text {
        margin-top: 6px;
        .ul_two {
          li {
            // width: 30%;
            // margin-left: 18px;
          }
        }
      }
    }
    .solar {
      height: 100px;
      .solar_title {
        margin-top: 8px;
        p {
          color: #333;
          margin-left: 2px;
        }
      }
      .solar_text {
        margin-top: 8px;
        .ul_two {
          li {
            height: 25px;
          }
        }
      }
    }
  }
}
</style>
