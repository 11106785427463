<template>
  <div class="Echarts">
    <!-- 年度闸泵开启统计 -->
    <div
      class="BrakePump card"
      v-loading="loading"
      element-loading-text="数据量过大，加载中..."
    >
      <Titles titles="年度闸泵开启统计" />
      <!-- 饼图 -->
      <div class="Pie">
        <Pump
          v-if="gateUpRecordVos.length !== 0"
          :gateUpRecordVos="gateUpRecordVos"
        />
        <div class="zanwu flex flex-a-c flex-j-c" v-else>
          <span class="font-s-20">暂无站点</span>
        </div>
      </div>
      <div class="PieData">
        <ul class="flex flex-j-s-b flex-a-c">
          <div>
            <li class="t-x">{{ percentage }}%</li>
            <p>同比去年上升</p>
          </div>
          <div>
            <li class="t-x">{{ count }}</li>
            <p>闸泵开启总次数</p>
          </div>
          <div>
            <li class="t-x">{{ unOpenCount }}</li>
            <p>未开启站点</p>
          </div>
        </ul>
      </div>
    </div>
    <!-- 年度水位曲线图 -->
    <div
      class="YearWater card"
      v-loading="loading"
      element-loading-text="数据量过大，加载中..."
    >
      <div class="TitlesR">
        <el-radio-group v-model="radio1" @change="changeRadio" size="mini">
          <el-radio-button label="1">月</el-radio-button>
          <el-radio-button label="2">年</el-radio-button>
        </el-radio-group>
        <el-select
          v-model="water"
          placeholder="请选择"
          size="mini"
          @change="waterChange"
          style="margin-left: 10px"
        >
          <el-option
            v-for="(item, index) in waterList"
            :key="item.deviceAddress"
            :label="index + 1 + ' ' + item.siteName"
            :value="item.deviceAddress"
          >
          </el-option>
        </el-select>
      </div>
      <Titles titles="水位曲线图" />
      <!-- 折线图 年 -->
      <div class="Year">
        <!--	年水位表			-->
        <YearWater v-if="Year" class="a1" :Level="Level" />
        <!--	月水位表		-->
        <YearMonthi v-if="YearMonthi" class="a2" :Level1="Level1"></YearMonthi>
      </div>
    </div>
    <!-- 年度流量曲线图 -->
    <div
      class="YearFlow card"
      v-loading="loading"
      element-loading-text="数据量过大，加载中..."
    >
      <div class="TitlesR">
        <el-radio-group v-model="radio2" @change="changeRadioio" size="mini">
          <el-radio-button label="1">月</el-radio-button>
          <el-radio-button label="2">年</el-radio-button>
        </el-radio-group>
        <el-select
          v-model="flow"
          placeholder="请选择"
          size="mini"
          @change="flowChange"
          style="margin-left: 10px"
        >
          <el-option
            v-for="(item, index) in waterList"
            :key="item.deviceAddress"
            :label="index + 1 + ' ' + item.siteName"
            :value="item.deviceAddress"
          >
          </el-option>
        </el-select>
      </div>

      <Titles titles="流量曲线图" />
      <div class="Flow">
        <!-- 年流量表 -->
        <YearFlow v-if="YearFlow" :Traffic="Traffic" />
        <!-- 月流量表 -->
        <YearFlowmonth v-if="YearFlowmonth" :Traffic1="Traffic1" />
      </div>
    </div>
  </div>
</template>

<script>
import Titles from "../../../../components/Titles";
import Pump from "./components/Pump";
import YearWater from "./components/YearWater";
import YearFlow from "./components/YearFlow";
import YearMonthi from "./components/YearMonth";
import YearFlowmonth from "./components/YearFlowmonth";
// 引入 年度闸泵开启统计 接口
import {
  gateUpRecordList,
  YearsList,
  siteGetSiteAll,
  FlowList,
  YearMonth,
  FlowListMonth,
} from "../../../../api/index";

export default {
  components: {
    Titles,
    Pump,
    YearWater,
    YearFlow,
    YearMonthi,
    YearFlowmonth,
  },
  data() {
    return {
      radio1: "2", // 水位存储值1是月 2是年
      radio2: "2",
      waterList: [], //拦河闸下拉
      water: "",
      flow: "",
      value: "",
      loading: false,
      gateUpRecordVosList: [],
      gateUpRecordVos: [], //处理的数据
      unOpenCount: "",
      percentage: "", // 同比去年上升
      count: "", //闸泵开启总次数
      unOpenCount: "", //未开启
      // 月度水位表
      Level: {
        dataX: [],
        dataY: [],
        dataZ: [],
      },
      // 年度水位表
      Level1: {
        dataX: [],
        dataY: [],
        dataZ: [],
      },
      // 年度流量表
      Traffic: {
        dataX: [],
        dataY: [],
        dataZ: [],
      },
      // 月度流量表
      Traffic1: {
        dataX: [],
        dataY: [],
        dataZ: [],
      },
      lastLevelList: [], // 后台返回的去年水位表
      nowLevelList: [], // 后台返回的今年水位表
      lastTrafficList: [], // 后台返回的去年流量表
      nowTrafficList: [], // 后台返回的今年流量表
      datakey: "", // 存储设备编号
      Year: true, // 年度窗口
      YearMonthi: false, //月度窗口
      YearFlowmonth: false, // 流量曲线月度
      YearFlow: true, //流量曲线图年度
    };
  },
  mounted() {
    // 年度闸泵开启统计
    this.loading = true;
    gateUpRecordList().then((res) => {
      this.gateUpRecordVosList = res.data.gateUpRecordVos;
      this.unOpenCount = res.data.punOpenCount;
      this.percentage = res.data.percentage.percentage; // 同比去年上升
      this.count = res.data.count; //闸泵开启总次数
      this.unOpenCount = res.data.unOpenCount; //未开启
      // 处理饼图的数据
      this.gateUpRecordVosList.forEach((item, index) => {
        setTimeout(() => {
          let obj = {};
          obj.value = item.count;
          obj.name = item.siteName;
          this.gateUpRecordVos.push(obj);
        }, (index + 1) * 100);
      });
      this.loading = false;
    });
    siteGetSiteAll().then((res) => {
      this.waterList = res.data;
      this.water = this.waterList[0].deviceAddress;
      this.flow = this.waterList[0].deviceAddress;
      this.dataProcessing(this.waterList[0].deviceAddress);
      this.dataProcessings(this.waterList[0].deviceAddress);
    });
  },
  methods: {
    // 年 月 1月 2是年
    changeRadio(val) {
      // 请求年的数据
      if (this.radio1 == 2) {
        this.Level.dataX = [];
        this.Level.dataY = [];
        this.Level.dataZ = [];
        this.YearMonthi = false;
        this.Year = true;
        YearMonth(this.datakey, this.radio1).then((res) => {
          //	返回月数据表
          this.lastLevelList = res.data.lastLevel;
          this.lastLevelList.forEach((item, index) => {
            setTimeout(() => {
              this.Level.dataX.push(item.month);
              this.Level.dataY.push(item.level);
            }, (index + 1) * 300);
          });
          // 后台返回的今年水位表
          this.nowLevelList = res.data.nowLevel;
          this.nowLevelList.forEach((item, index) => {
            setTimeout(() => {
              this.Level.dataZ.push(item.level);
            }, (index + 1) * 300);
          });
        });
        //	请求月的数据
      } else if (this.radio1 == 1) {
        this.Year = false;
        this.YearMonthi = true;
        this.Level1.dataX = [];
        this.Level1.dataY = [];
        this.Level1.dataZ = [];
        YearMonth(this.datakey, this.radio1).then((res) => {
          //	返回今年月度数据表
          this.lastLevelList = res.data.lastLevel;
          this.lastLevelList.forEach((item, index) => {
            setTimeout(() => {
              this.Level1.dataX.push(item.day);
              this.Level1.dataY.push(item.level);
            }, (index + 1) * 300);
          });
          // 后台返回的今年月度水位表
          this.nowLevelList = res.data.nowLevel;
          this.nowLevelList.forEach((item, index) => {
            this.Level1.dataZ = [];
            setTimeout(() => {
              this.Level1.dataZ.push(item.level);
            }, (index + 1) * 300);
          });
        });
      }
    },
    // 数据处理 年
    dataProcessing(val) {
      this.datakey = val;
      // 进入页面获取年、月数据
      YearsList(val, this.radio1).then((res) => {
        // 进入页面获取年度数据
        this.lastLevelList = res.data.lastLevel;
        this.lastLevelList.forEach((item, index) => {
          setTimeout(() => {
            // 根据1-2判断数据返回年窗口还是月窗口，如果是1将数据返回到Level1
            // 如果是年、2返回到年的Level数组
            if (this.radio1 == 1) {
              this.Level1.dataX.push(item.day);
              this.Level1.dataY.push(item.level);
            } else if (this.radio1 == "" || this.radio1 == 2) {
              this.Level.dataX.push(item.month);
              this.Level.dataY.push(item.level);
            }
          }, (index + 2) * 300);
        });
        // 后台返回的今年水位表
        this.nowLevelList = res.data.nowLevel;
        this.nowLevelList.forEach((item, index) => {
          setTimeout(() => {
            if (this.radio1 == 1) {
              this.Level1.dataZ.push(item.level);
            } else if (this.radio1 == 2 || this.radio1 == "") {
              this.Level.dataZ.push(item.level);
            }
          }, (index + 1) * 300);
        });
      });
    },
    // 流量曲线图 1 按月查  2 按年查
    changeRadioio(val) {
      if (val == 2) {
        this.YearFlow = true;
        this.YearFlowmonth = false;
        this.Traffic.dataX = [];
        this.Traffic.dataY = [];
        this.Traffic.dataZ = [];
        FlowListMonth(this.datakey, val).then((res) => {
          this.lastTrafficList = res.data.lastTraffic;
          this.lastTrafficList.forEach((item, index) => {
            setTimeout(() => {
              // 根据1-2判断数据返回年窗口还是月窗口，如果是1将数据返回到Traffic1
              // 如果是年、2返回到年的Traffic数组
              if (this.radio2 == 2 || this.radio2 == "") {
                this.Traffic.dataX.push(item.month);
                this.Traffic.dataY.push(item.sumTraffic);
              } else if (this.radio2 == 1) {
                this.Traffic1.dataX.push(item.day);
                this.Traffic1.dataY.push(item.sumTraffic);
              }
            }, (index + 1) * 300); // 索引作为数据当中的显示
          });
          //	返回去年数据
          this.nowTrafficList = res.data.nowTraffic;
          this.nowTrafficList.forEach((item, index) => {
            setTimeout(() => {
              if (this.radio2 == 2 || this.radio2 == "") {
                this.Traffic.dataZ.push(item.sumTraffic);
              } else if (this.radio2 == 1) {
                this.Traffic1.dataZ.push(item.sumTraffic);
              }
            });
          });
        });
        //	判断当前切换栏为1
      } else if (this.radio2 == 1) {
        // 如果为1开启月的窗口，关闭年的窗口，并且清除之前的数组中存储的数据
        // 关闭年的窗口
        this.YearFlow = false;
        // 开启月的窗口
        this.YearFlowmonth = true;
        this.Traffic1.dataX = [];
        this.Traffic1.dataY = [];
        this.Traffic1.dataZ = [];
        // 发送请求 this.datakey设备编号，当前tab栏的数值1是月、2是年
        FlowListMonth(this.datakey, val).then((res) => {
          this.lastTrafficList = res.data.lastTraffic;
          this.lastTrafficList.forEach((item, index) => {
            setTimeout(() => {
              this.Traffic1.dataX.push(item.day);
              this.Traffic1.dataY.push(item.sumTraffic);
            }, (index + 1) * 300);
          });
          // 返回去年数据
          this.nowTrafficList = res.data.nowTraffic;
          this.nowTrafficList.forEach((item, index) => {
            this.Traffic1.dataZ = [];
            setTimeout(() => {
              this.Traffic1.dataZ.push(item.sumTraffic);
            });
          });
        });
      }
    },
    // 流量表数据
    dataProcessings(val) {
      // 获去后台返回的数据
      FlowList(val, this.radio2).then((res) => {
        // 后台返回的去年流量表
        this.lastTrafficList = res.data.lastTraffic;
        this.lastTrafficList.forEach((item, index) => {
          setTimeout(() => {
            // 1 是月
            if (this.radio2 == 1) {
              this.Traffic1.dataX.push(item.day);
              this.Traffic1.dataY.push(item.sumTraffic);
              // 2 是年
            } else if (this.radio2 == 2 || this.radio2 == "") {
              this.Traffic.dataX.push(item.month);
              this.Traffic.dataY.push(item.sumTraffic);
            }
          }, (index + 1) * 300);
        });
        // 后台返回的今年流量表
        this.nowTrafficList = res.data.nowTraffic;
        this.nowTrafficList.forEach((item, index) => {
          setTimeout(() => {
            // 1 如果判断是1的话将数据push到Traffic1 月
            if (this.radio2 == 1) {
              this.Traffic1.dataZ.push(item.sumTraffic);
            } else if (this.radio2 == 2 || this.radio2 == "") {
              // 2 如果判断是2的将数据push到Traffic 年
              this.Traffic.dataZ.push(item.sumTraffic);
            }
          }, (index + 1) * 300);
        });
      });
    },
    waterChange(val) {
      this.Level.dataX = [];
      this.Level.dataY = [];
      this.Level.dataZ = [];
      this.Level1.dataX = [];
      this.Level1.dataY = [];
      this.Level1.dataZ = [];
      this.dataProcessing(val);
    },
    flowChange(val) {
      this.Traffic.dataX = [];
      this.Traffic.dataY = [];
      this.Traffic.dataZ = [];
      this.Traffic1.dataX = [];
      this.Traffic1.dataY = [];
      this.Traffic1.dataZ = [];
      this.dataProcessings(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.Echarts {
  width: calc(443 / 1920 * 100vw);
  padding: 0 calc(21 / 1080 * 100vh);
  padding-top: calc(2 / 1080 * 100vh);

  .BrakePump {
    height: calc(356 / 1080 * 100vh);

    .Pie {
      width: 100%;
      height: calc(220 / 1080 * 100vh);
    }

    .PieData {
      margin: 0 calc(17 / 1920 * 100vw);
      border-top: calc(1 / 1080 * 100vh) solid #e5e5e5;

      ul {
        height: calc(87 / 1080 * 100vh);
        color: #4174b7;

        div {
          li {
            height: calc(32 / 1080 * 100vh);
            font-size: calc(24 / 1920 * 100vw);
          }

          p {
            color: #666666;
            font-size: calc(16 / 1920 * 100vw);
          }
        }
      }
    }
  }

  .YearWater {
    height: calc(265 / 1080 * 100vh);
    position: relative;
    @media screen and (max-height: 650px) {
      height: calc(268 / 1080 * 100vh);
    }
    @media screen and (max-height: 620px) {
      height: calc(270 / 1080 * 100vh);
    }

    .Year {
      width: 100%;
      height: calc(210 / 1080 * 100vh);
    }
  }

  .YearFlow {
    height: calc(265 / 1080 * 100vh);
    position: relative;
    @media screen and (max-height: 650px) {
      height: calc(268 / 1080 * 100vh);
    }
    @media screen and (max-height: 620px) {
      height: calc(270 / 1080 * 100vh);
    }

    .Flow {
      width: 100%;
      height: calc(210 / 1080 * 100vh);
    }
  }
}

.zanwu {
  color: #333;
  height: calc(200 / 1080 * 100vh);
}

.TitlesR {
  position: absolute;
  top: 8px;
  right: 5px;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
