<template>
  <div class="flood">
    <!-- 头部区域 -->
    <Header />
    <div class="main flex">
      <Echarts />
      <Map :key="mapReload" :siteId="siteId" />
      <Table />
    </div>
    <div class="footer flex flex-j-c">
      <div class="gif-top">
        <img src="../../assets/images/hecheng-3.gif">
      </div>
      <ul class="flex boxul">
        <router-link to="/simpleness" v-if="$store.getters.isNavItems('首页')">
          <div>
            <li class="home-pie pic-box"></li>
            <p>首页</p>
          </div>
        </router-link>
        <router-link
              :to="{ path: `/dataV` }"
          >
              <div>
                  <li class="data-v pic-box"></li>
                  <p>大数据</p>
              </div>
          </router-link>
          <router-link
                  v-if="$store.getters.isNavItems('泵站管理')"
                  :to="{ path: `/main/pumpStation/${this.bzid}/1-1` }"
          >
              <div>
                  <li class="pump-pie pic-box"></li>
                  <p>泵站管理</p>
              </div>
          </router-link>
        <router-link
          v-if="$store.getters.isNavItems('拦河闸管理')"
          :to="{ path: `/main/StationManage/${this.siteId}` }"
        >
          <div>
            <li class="control-pie pic-box"></li>
            <p>拦河闸管理</p>
          </div>
        </router-link>
        <!-- <router-link
          :to="{ path: `/main/WaterLevel/${this.siteId}` }"
          v-if="$store.getters.isNavItems('水位监测')"
        >
          <div>
            <li class="waterLevel-pie pic-box"></li>
            <p>水位监测</p>
          </div>
        </router-link> -->
        <router-link
          to="/main/RainCondition/RainFall"
          v-if="$store.getters.isNavItems('雨情遥测')"
        >
          <div>
            <li class="telemetering-pie pic-box"></li>
            <p>雨情遥测</p>
          </div>
        </router-link>
        <router-link
          :to="{ path: `/main/flowMeter` }"
          v-if="$store.getters.isNavItems('测流点管理')"
        >
          <div>
            <li class="rationWater-pie pic-box"></li>
            <p>测流点管理</p>
          </div>
        </router-link>
        <router-link
          :to="{ path: `/main/cldHistory/${this.siteId}` }"
          v-if="$store.getters.isNavItems('测流点管理')"
        >
          <div>
            <li class="monitoring-pie pic-box"></li>
            <p>监控总览</p>
          </div>
        </router-link>
        <router-link
          to="/main/Capture"
          v-if="$store.getters.isNavItems('非法抓拍')"
        >
          <div>
            <li class="capture-pie pic-box"></li>
            <p>非法抓拍</p>
          </div>
        </router-link>
        <router-link
          to="/main/SearchManage/FlowStatistics"
          v-if="$store.getters.isNavItems('查询管理')"
        >
          <div>
            <li class="equipment-pie pic-box"></li>
            <p>查询管理</p>
          </div>
        </router-link>
        <!-- <router-link
          to="/main/FacilityBack"
          v-if="$store.getters.isNavItems('设备追回')"
        >
          <div>
            <li class="trace-pie pic-box"></li>
            <p>设备追回</p>
          </div>
        </router-link> -->
        <!-- <router-link
          to="/main/AlarmLog"
          v-if="$store.getters.isNavItems('报警记录')"
        >
          <div>
            <li class="alarmRecord-pie pic-box"></li>
            <p class="clock">报警记录</p>
          </div>
        </router-link> -->
      </ul>
    </div>
    <!-- <div class="timeOver">刷新倒计时：{{ time }}秒</div> -->
  </div>
</template>

<script>
import Header from '../../components/Header/index'
import Echarts from './components/Echarts/index'
import Map from './components/Map/index'
import Table from './components/Table/index'
import { getSitesList, getPumpStationMain } from '../../api/main.js'
export default {
  created () {
    if (!this.$ws.readyState()) {
      this.$ws.create()
    }
  },
  mounted () {
    this.$store.commit('getNavItems') // 获取菜单列表
    getSitesList().then(res => {
      // console.log(res);
      this.siteId = String(res.data[0].id)
    })
    getPumpStationMain().then(res => {
      // console.log(res)
      const { id, staId } = res.data[0]
      this.bzid = id
      this.staId = staId
    })
    // this.time = 15;
    // // clearInterval(this.timer);
    // this.timer = setInterval(() => {
    //   if (this.time <= 0) {
    //     // this.$router.go(0);
    //     this.mapReload = Date.now();
    //     this.time = 16;
    //     // 修改选中的地方
    //     // this.currentIcon = 0;
    //   }
    //   this.time--;
    // }, 1000);
  },
  data () {
    return {
      show: false,
      time: 15,
      timer: null,
      siteId: '', // 站点id,
      mapReload: Date.now(), // 用于刷新地图,
      bzid: '',
      staId: ''
    }
  },
  methods: {
    destroyed () {
      clearInterval(this.timer)
      this.timer = null
    },
    openBox () {
      this.show = true
    },
    closeBox () {
      this.show = false
    }
  },
  components: {
    Header,
    Echarts,
    Map,
    Table
  }
}
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: calc(926 / 1080 * 100vh);
}
.footer {
  height: calc(64 / 1080 * 100vh);
  // background: url("../../assets/images/index/footer.png") no-repeat;
  background-size: 100% 100%;
  position: relative;
  // opacity: 0;
  transition: opacity 800ms;
  // background: none;
  >ul{
    opacity: 0;
  }
  &:hover {
    background-image: url("../../assets/images/index/footer.png");
    background-repeat: no-repeat;
    >ul{
      opacity: 1;
    }
    .gif-top{
      opacity: 0;
    }
  }
  .gif-top{
    position: absolute;
    bottom: calc(11.25 / 1080 * 100vh);
    pointer-events: none;
    user-select: none;
  }
  p {
    font-size: calc(16 / 1920 * 100vw);
    font-weight: normal;
    color: #ffffff;
  }
  // 首页
  .home-pie {
    background: url("../../assets/images/footerMenu/home.png") no-repeat;
    background-size: 90% auto;
    background-position: center;
  }
    // 大数据
 .data-v {
     background: url("../../assets/images/footerMenu/dataV.png") no-repeat;
     background-size: 90% auto;
     background-position: center;
  }
  // 泵站管理
 .pump-pie {
     background: url("../../assets/images/footerMenu/pump.png") no-repeat;
     background-size: 90% auto;
     background-position: center;
  }
  // 站点管理
  .control-pie {
    background: url("../../assets/images/footerMenu/control.png") no-repeat;
    background-size: 90% auto;
    background-position: center;
  }
  // 测流点管理
  .rationWater-pie {
    background: url("../../assets/images/footerMenu/flowmeter.png") no-repeat;
    background-size: 90% auto;
    background-position: center;
  }
  // 水位监测
  .waterLevel-pie {
    background: url("../../assets/images/footerMenu/waterLevel.png") no-repeat;
    background-size: 90% auto;
    background-position:center;
  }
  // 雨情遥测
  .telemetering-pie {
    background: url("../../assets/images/footerMenu/telemetering.png") no-repeat;
    background-size: 90% auto;
    background-position: center;
  }
  // 监控总览
  .monitoring-pie {
    background: url("../../assets/images/footerMenu/monitoring.png") no-repeat;
    background-size: 90% auto;
    background-position: center;
  }
  // 非法抓拍
  .capture-pie {
    background: url("../../assets/images/footerMenu/capture.png") no-repeat;
    background-size: 90% auto;
    background-position: center;
  }
  // 设备管理
  .equipment-pie {
    background: url("../../assets/images/footerMenu/equipment.png") no-repeat;
    background-size: 90% auto;
    background-position: center;
  }
  // 设备追踪
  .trace-pie {
    background: url("../../assets/images/footerMenu/trace.png") no-repeat;
    background-size: 90% auto;
    background-position: center;
  }
  // 报警记录
  .alarmRecord-pie {
    background: url("../../assets/images/footerMenu/alarmRecord.png") no-repeat;
    background-size: 90% auto;
    background-position: center;
  }
  ul {
    margin-left: calc(24 / 1920 * 100vw);

    div {
      text-align: center;
      position: relative;
      bottom: calc(45 / 1080 * 100vh);
      li {
        width: calc(65 / 1920 * 100vw);

        margin: 0 calc(8 / 1920 * 100vw) 0 calc(8 / 1920 * 100vw);
      }
    }
  }
}
.timeOver {
  z-index: 9999;
  position: absolute;
  top: calc(110 / 1080 * 100vh);
  left: calc(1300 / 1920 * 100vw);
  font-size: calc(10 / 1920 * 100vw);
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #fe6800;
}

.router-link-active {
  text-decoration: none;
  color: #ffd04b;
}
.boxul{
  a{
     margin-left: calc(30 / 1920 * 100vw);
  }
}
a {
  text-decoration: none;
  color: #fff;
}
</style>
