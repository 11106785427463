<template>
  <div class="Table">
    <Cardbox />
    <Swipers />
    <Police />
  </div>
</template>

<script>
import Cardbox from "./components/Cardbox";
import Swipers from "./components/Swipers";
import Police from "./components/Police";

export default {
  data() {
    return {};
  },
  components: {
    Cardbox,
    Swipers,
    Police,
  },
};
</script>

<style lang="scss" scoped>
.Table {
  width: calc(446 / 1920 * 100vw);
  padding: calc(21 / 1920 * 100vw);
}
</style>
