<template>
	<div id="WaterFlows"></div>
</template>

<script>
	export default {
		props: {
			Traffic: {
				type: Object,
				required: true
			}
		},
		data() {
			return {
				WaterFlows: null
			};
		},
		watch: {
			Traffic: {
				deep: true,
				handler(val) {
					this.setOptions(val);
				}
			}
		},
		created() {
		},
		mounted() {
			this.$nextTick(() => {
				this.initChart();
			});
		},
		beforeDestroy() {
			if (!this.WaterFlows) {
				return;
			}
			this.WaterFlows.dispose();
			this.WaterFlows = null;
		},
		destroyed() {
			window.removeEventListener("resize", () => {
			});
		},
		methods: {
			initChart() {
				// 基于准备好的dom，初始化echarts实例
				this.WaterFlows = this.$echarts.init(
					document.getElementById("WaterFlows")
				);
				window.addEventListener("resize", () => {
					this.WaterFlows.resize();
				});
				// 绘制图表
				this.setOptions(this.Traffic);
			},
			setOptions({dataX, dataY, dataZ} = {}) {
				this.WaterFlows.setOption({
					tooltip: {
						trigger: 'axis',
						formatter: '{a1} {b1} 月：{c1} m³ <br> {a0} {b0} 月：{c0} m³'
					},
					xAxis: {
						data: dataX,
						type: "category",
						// boundaryGap: false, //x轴从零开始
						// x轴上的字体颜色样式
						axisLabel: {
							show: true,
							textStyle: {
								color: "#C7C7C7"
							}
						},
						// x轴上颜色样式
						axisLine: {
							lineStyle: {
								color: "#C7C7C7",
								width: 1 //这里是坐标轴的宽度
							}
						},
						axisTick: {
							//x轴刻度线
							show: false
						}
					},
					yAxis: {
						name: "单位/m³",
						type: "value",
						// y轴上的字体颜色样式
						axisLabel: {
							show: true,
							textStyle: {
								color: "#C7C7C7"
							}
						},
						// y轴上颜色样式
						axisLine: {
							lineStyle: {
								color: "#C7C7C7",
								width: 1 //这里是坐标轴的宽度
							}
						},
						axisTick: {
							//y轴刻度线
							show: false
						}
					},
					grid: {
						top: "28%",
						left: "15%",
						bottom: "18%",
						right: "5%"
					},
					series: [
						{
							data: dataY,
							type: "line",
							name: "去年"
						},
						{
							data: dataZ,
							type: "line",
							name: "今年"
						}
					],
					color: ["#c23531", "#4073B6"],

					legend: {
						show: true,
						data: ["去年", "今年"]
					}
				});
			}
		}
	};
</script>

<style lang="scss" scoped>
	#WaterFlows {
		width: 100%;
		height: 100%;
	}
</style>
